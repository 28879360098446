@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

* {
    margin: 0;
    padding: 0;
}

body{
    background-color: #1D2128;
    
}

hr {
    background-color: rgba(229, 229, 229, 0.2);
    border: 1px solid rgba(229, 229, 229, 0.2);
}

a:link, a:visited, a:hover a:active{
    text-decoration: none;
    color: inherit;
}